export const RECOVER_PASSWORD_VIEW = 'RECOVER_PASSWORD_VIEW'
export const REDEFINE_PASSWORD_VIEW = 'REDEFINE_PASSWORD_VIEW'
export const LOGIN_VIEW = 'LOGIN_VIEW'
export const DASHBOARD = 'DASHBOARD'
export const HOME_VIEW = 'HOME_VIEW'
export const RECURRENCE_SELECTOR_VIEW = 'RECURRENCE_SELECTOR_VIEW'
export const PAYMENT_METHOD_SELECTOR_VIEW = 'PAYMENT_METHOD_SELECTOR_VIEW'
export const CHECKOUT_PROFILE_EDIT_VIEW = 'CHECKOUT_PROFILE_EDIT_VIEW'
export const PLAN_SELECTOR_VIEW = 'PLAN_SELECTOR_VIEW'
export const AUTH_LAYOUT = 'AUTH_LAYOUT'
export const CHECKOUT_LAYOUT = 'CHECKOUT_LAYOUT'
export const MAIN_LAYOUT = 'MAIN_LAYOUT'
export const IN_PROGRESS = 'IN_PROGRESS'
export const CANCEL_PLAN_VIEW = 'CANCEL_PLAN_VIEW'
export const EDIT_STORE_VIEW = 'EDIT_STORE_VIEW'
export const CHANGE_PAYMENT_METHOD_VIEW = 'CHANGE_PAYMENT_METHOD_VIEW'
export const CHANGE_TO_BANKSLIP = 'CHANGE_TO_BANKSLIP'
export const CHANGE_TO_CREDIT_CARD = 'CHANGE_TO_CREDIT_CARD'
export const CHECKOUT_BANKSLIP_VIEW = 'CHECKOUT_BANKSLIP_VIEW'
export const BANKSLIP_PDF_VIEW = 'BANKSLIP_PDF_VIEW'
export const CHECKOUT_CREDIT_CARD_VIEW = 'CHECKOUT_CREDIT_CARD_VIEW'
export const MIGRATION_PLAN_VIEW = 'MIGRATION_PLAN_VIEW'
export const MIGRATION_CONFIRMATION_VIEW = 'MIGRATION_CONFIRMATION_VIEW'
export const MIGRATION_END_VIEW = 'MIGRATION_END_VIEW'
export const LEGACY_TOKEN_VIEW = 'LEGACY_TOKEN_VIEW'
export const BANKSLIP_VIEW = 'BANKSLIP_VIEW'
export const CHECKOUT_CREDIT_CARD_SUCCESS = 'CHECKOUT_CREDIT_CARD_SUCCESS'
export const UNAVAILABLE_VIEW = 'UNAVAILABLE_VIEW'

import { ErrorBackendDefault } from '@/utils/ErrorDefault'
import axios from 'axios'
class AxiosHttp {

  backendUrl = process.env.VUE_APP_BACKEND_URL
  accessToken = localStorage.getItem('nex-token')
  http


  constructor() {
    const defaultAxios = axios.create({
      baseURL: this.backendUrl,
      headers: {
        'Content-Type': 'application/json',
      }
    })
    this.http = defaultAxios
    this.defineInterceptor(this.http);
  }

  defineInterceptor(api) {
    api.interceptors.request.use(
      config => {
        const token = localStorage.getItem('nex-token');
        if (token) {
          config.headers.common["Authorization"] = `Bearer ${token}`;
        }
        return config;
      },
      error => {
        Promise.reject(error);
      }
    ),
      api.interceptors.response.use(
        response => {
          return response;
        },
        error => {
          if (error && error?.response?.status === 401) {
            localStorage.clear();
            window.location = 'login';
          } else {
            return Promise.reject(error);
          }
        }
      )
  }


  async get(route, data = '') {
    const response = await this.http.get(this.backendUrl + route + data)
      .catch(error => this.errorReturn(error));

    return response.data

  }
  async post(route, data) {

    const response = await this.http.post(this.backendUrl + route, data)
      .catch(error => this.errorReturn(error));
    return response.data

  }
  async put(route, data) {

    const response = await this.http.put(this.backendUrl + route, data)
      .catch(error => this.errorReturn(error));
    return response.data

  }
  async delete(route, data) {

    const response = await this.http.delete(this.backendUrl + route, data)
      .catch(error => this.errorReturn(error));
    return response.data

  }

  errorReturn(error) {
    if (error?.response?.data) {
      throw new ErrorBackendDefault(error.response.data)
    } else if (error?.response) {
      throw error.response
    } else if (error.request) {
      throw error.request
    } else {
      throw error
    }
  }
}

export default new AxiosHttp()

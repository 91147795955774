var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "loading-wrapper" } }, [
    _c("div", { attrs: { id: "loading-text" } }, [
      _vm._v(_vm._s(_vm.$t("components.loading.message")) + " …"),
    ]),
    _c("div", { attrs: { id: "loading-content" } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
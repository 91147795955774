<template>
  <label :for="context.id" :class="context.classes.label">
    {{ context.label }}
  </label>
</template>

<script>
export default {
  props: {
    context: {
      type: Object,
      required: true,
    },
    icon: {
      type: [String, Boolean],
      default: false,
    },
  },
  data() {
    return {};
  },
};
</script>

<style>
</style>
import axios from 'axios'
export default class Gateway {
  gateway = ""
  stripePublicEndPoint = `${process.env.VUE_APP_STRIPE_API}/tokens`
  stripePublicKey = process.env.VUE_APP_STRIPE_PUBLIC_KEY
  constructor(gateway) {
    this.gateway = gateway;
  }
  async tokenize(data) {
    const stripe = await this.stripeTokenize(data)
    return {
      stripe: stripe
    }
  }
  async stripeTokenize(data) {
    const bodyParameters = new URLSearchParams({
      'card[number]': data.card_number,
      'card[exp_month]':  data.card_expiration.split("/")[0],
      'card[exp_year]': data.card_expiration.split("/")[1],
      'card[cvc]': data.card_cvv,
      'card[name]': data.holder_name,
    });
    try {
      const result = await this.postStripe(bodyParameters)
      return result
    } catch (err) {
      return new Error(JSON.stringify(err))
    }
  }
  async postStripe( data) {
    const config = {
      headers: { 
        Authorization: `Bearer ${this.stripePublicKey}`,
        'Content-Type': 'application/x-www-form-urlencoded'
     }
    };
    const response = await axios.post( this.stripePublicEndPoint, data, config)
    .catch(error => this.errorReturn(error));
    return response.data
  }
}

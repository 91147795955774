import AxiosHttp from "@/services/AxiosHttp";
import { SET_IS_APPLICATION_LOADING } from "@/store/constants/app";
import { GET_STORE, SET_STORE, UPDATE_PROFILE } from "@/store/constants/store";

export default {
  [GET_STORE]: async ({ commit }) => {
    commit(SET_IS_APPLICATION_LOADING, true, { root: true });
    const response = await AxiosHttp.get('/store/profile')
      .catch(error => {
        commit(SET_IS_APPLICATION_LOADING, false, { root: true });
        throw error
      }).finally(() => {
        commit(SET_IS_APPLICATION_LOADING, false, { root: true });
      }
      )
    if (response.success) {
      const redirect = "BR"
      const { country } = response.payload.billingAddress

      if (redirect.includes(country)) {
        const redirectUrl = process.env.MINHALOJA_NACIONAL
        const token = localStorage.getItem("nex-token")
        localStorage.clear("nex-token")
        window.location.href = redirectUrl.slice(0, redirectUrl.length - 1) + `?token=${token}`
        return
      }

      commit(SET_STORE, response.payload);
    }
    return response
  },
  [UPDATE_PROFILE]: async ({ commit }, updateData) => {
    commit(SET_IS_APPLICATION_LOADING, true, { root: true });
    return AxiosHttp.put('/store/profile', updateData)
      .catch(error => {
        commit(SET_IS_APPLICATION_LOADING, false, { root: true });
        throw error
      }).finally(() => {
        commit(SET_IS_APPLICATION_LOADING, false, { root: true });
      })
  },


}

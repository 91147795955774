import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import './plugins/intercom'
import './plugins/forms'
import './plugins/luckyorange'
import VueSimpleAlert from 'vue-simple-alert'
import './assets/styles/main.scss'
import { NoIntercomPage } from './constants'
import Modal from '@burhanahmeed/vue-modal-2'
import VueIziToast from 'vue-izitoast';
import 'izitoast/dist/css/iziToast.min.css';


Vue.config.productionTip = false
Vue.use(VueIziToast);
Vue.use(VueSimpleAlert, { reverseButtons: false })
Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.eventSetDrag = function () {
      el.setAttribute('data-dragging', 'yes');
    }
    el.eventClearDrag = function () {
      el.removeAttribute('data-dragging');
    }
    el.eventOnClick = function (event) {
      let dragging = el.getAttribute('data-dragging');
      // Check that the click was outside the el and its children, and wasn't a drag
      if (!(el == event.target || el.contains(event.target)) && !dragging) {
        // call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.addEventListener('touchstart', el.eventClearDrag);
    document.addEventListener('touchmove', el.eventSetDrag);
    document.addEventListener('click', el.eventOnClick);
    document.addEventListener('touchend', el.eventOnClick);
  }, unbind: function (el) {
    document.removeEventListener('touchstart', el.eventClearDrag);
    document.removeEventListener('touchmove', el.eventSetDrag);
    document.removeEventListener('click', el.eventOnClick);
    document.removeEventListener('touchend', el.eventOnClick);
    el.removeAttribute('data-dragging');
  },
});
Vue.use(Modal)

new Vue({
  router,
  store,
  i18n,
  watch: {
    '$route': (route) => {
      if (NoIntercomPage.includes(route.name)) {
        window.Intercom('shutdown')
      } else {
        window.Intercom('update')
      }
    }
  },
  render: h => h(App)
}).$mount('#app')

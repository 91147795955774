var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "label",
    { class: _vm.context.classes.label, attrs: { for: _vm.context.id } },
    [_vm._v(" " + _vm._s(_vm.context.label) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
    state: {
        plan: null,
        plans: null,
        chosenPlan: null,
        migrationResult: null,
    },
    actions,
    getters,
    mutations,
    namespaced: true
}
import Vue from 'vue'
import VueFormulate from '@braid/vue-formulate'
import Label from '../components/Form/Label.vue'
Vue.component('Label', Label)

const options = {
    classes: {
        input: 'nex-input',
    },
    slotComponents: {
        label: 'Label',
    },
    slotProps: {
        label: ['icon'],
    },
}
Vue.use(VueFormulate, options)

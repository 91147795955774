import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
    state: {
        store: {},
        checkoutInfos: {
            sellerId: process.env.VUE_APP_CHECKOUT_SELLER_ID,
            buyerId: null,
        },
    },
    actions,
    getters,
    mutations,
    namespaced: true
}
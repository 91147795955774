export class ErrorBackendDefault {
    message
    status
    errors
    jsonErrors

    constructor(errorPayload) {
        this.message = errorPayload.error
        this.status = errorPayload.statusCode
        this.mountErrors(errorPayload.message)
        this.jsonErrors = JSON.stringify(this.errors)
    }

    mountErrors(dataError) {
        if (!dataError) {
            return
        }
        const errors = []
        for (const error of dataError) {
            errors.push({ message: error?._message, payload: error?._value })
        }

        this.errors = errors
    }

}


export const SET_LANGUAGE = 'SET_LANGUAGE'
export const SET_TOKEN = 'SET_TOKEN'
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'
export const LOGIN = 'LOGIN'
export const LOGIN_MODULE = 'login'
export const CHECK_TOKEN = 'CHECK_TOKEN'
export const RECOVER_PASSWORD = 'RECOVER_PASSWORD'
export const REDEFINE_PASSWORD = 'REDEFINE_PASSWORD'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const CHANGE_LOADING = 'CHANGE_LOADING'
export const CHANGE_TOKEN = 'CHANGE_TOKEN'
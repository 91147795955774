import AxiosHttp from "@/services/AxiosHttp";
import { CHECK_TOKEN, LOGIN, RECOVER_PASSWORD, REDEFINE_PASSWORD, SET_TOKEN, AUTH_LOGOUT, CHANGE_TOKEN } from "@/store/constants/login";
import { LOGIN_VIEW } from "@/router/constants"
import router from '../../../router'
export default {
  [LOGIN]: async ({ commit }, data) => {
    const response = await AxiosHttp.post('/auth', data)
      .catch(error => {
        throw error
      })
    if (response.redirectUrl && !response.redirectUrl.includes("my.nextar")) {
      window.location.href = response.redirectUrl
      return
    }
    localStorage.setItem('nex-token', response.token)
    commit(SET_TOKEN, response.token)

    return response

  },
  [CHECK_TOKEN]: async ({ getters }) => {
    return localStorage.getItem('nex-token') || getters.token
  },
  [AUTH_LOGOUT]: async () => {
    localStorage.clear();
    router.push({ name: LOGIN_VIEW });
  },
  [RECOVER_PASSWORD]: async (_context, data) => {
    return AxiosHttp.post('/auth/recovery-password', data)
      .catch(error => {
        throw error
      })

  },
  [REDEFINE_PASSWORD]: async (_context, data) => {
    const response = await AxiosHttp.post('/auth/redefine-password', data)
      .catch(error => {
        throw error
      })

    return response
  },
  [CHANGE_TOKEN]: async ({ commit }, token) => {
    commit(SET_TOKEN, token)
    localStorage.setItem('nex-token', token)
  },

}

import { LOGIN_VIEW, RECOVER_PASSWORD_VIEW, REDEFINE_PASSWORD_VIEW } from "@/router/constants"

const Recurrences = {
  1: 'Mensal',
  6: 'Semestral',
  12: 'Anual',
}

const Plans = {
  1: 'Free',
  5: 'Premium',
  6: 'Pro',
  7: 'Fiscal',
}
const PlansdefaultNames = {
  5: 'PREMIUM V3',
  6: 'PRO V3',
  7: 'FISCAL V3',
}

const PaymentTypes = {
  1: 'Boleto Bancário',
  2: 'Cartão de Crédito',
  50: 'Pix',
  100: 'PagoFacil',
  101: 'RapiPago'
}

const AbbreviationLang = {
  'pt-BR': 'BR',
  'es-ES': 'ES',
  'en-US': 'US',
}

const defaultPlansNames = {
  FISCAL: 'FISCAL V3',
  PRO: 'PRO V3',
  PREMIUM: 'PREMIUM V3'
}

const AccessOrigin = {
  DESKTOP: 'desktop',
}

const gateways = {
  VINDI: 'Vindi',
  PAGARME: 'Pagarme',
  ITAU: 'Itau',
  STRIPE: 'Stripe',
  EBANX: 'Ebanx'
}
const Currency = {
  USD: 'US$',
  ARS: '$', 
  BRL: 'R$'
}

const recurrences = {
  1: 'monthly',
  6: 'semiannual',
  12: 'annual',
}

const NoIntercomPage = [LOGIN_VIEW, RECOVER_PASSWORD_VIEW, REDEFINE_PASSWORD_VIEW]

export {
  Recurrences,
  Plans,
  PlansdefaultNames,
  PaymentTypes,
  NoIntercomPage,
  AbbreviationLang,
  defaultPlansNames,
  gateways,
  recurrences,
  AccessOrigin, 
  Currency
}

<template>
    <div id="app">
        <loading v-if="isApplicationLoading" />
        <router-view />
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { AUTH_LOGOUT, LOGIN_MODULE } from "./store/constants/login";
import { STORE_MODULE } from "./store/constants/store";
import Loading from "@/components/Loading.vue";
export default {
    components: {
        Loading,
    },


    mounted() {
        // TODO: Ajustar aqui para aceitar clientes internacionais, não deve buscar do navegador, mas do idioma definido de alguma forma pelo próprio cliente, sendo o navegador a última opção se nada for definido previamente
        this.$i18n.locale = process.env.VUE_APP_I18N_LOCALE; // window.navigator.language;
    },

    computed: {
        ...mapGetters({
            intercomData: `${STORE_MODULE}/intercomData`,
            isApplicationLoading: "isApplicationLoading",
        }),
    },
    watch: {
        intercomData() {
            //após teste mudar condição para production
            if (this.intercomData) {
                const customData = {
                    shopCode: this.intercomData.company.id,
                    email: this.intercomData.conta,
                    name: this.intercomData.name,
                };
                this.$intercom.boot(this.intercomData);
                window._loq = window._loq || [];
                window._loq.push(["custom", customData]);
            } else {
                this.logout();
            }
        },
    },
    methods: {
        ...mapActions({
            logout: `${LOGIN_MODULE / AUTH_LOGOUT}`,
        }),
    },
};
</script>

<style lang="scss">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
</style>

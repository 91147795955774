export const SET_IS_APPLICATION_LOADING = 'SET_IS_APPLICATION_LOADING'
export const SET_ACCESS_ORIGIN = 'SET_ACCESS_ORIGIN'
export const CHANGE_ACCESS_ORIGIN = 'CHANGE_ACCESS_ORIGIN'




















